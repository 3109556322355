import React from "react"

import { Box, Text } from "@chakra-ui/core"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "containers/layout"
import SEO from "components/seo"
import Masonry from "containers/masonry"
import Article from "containers/article"
import { onlyClient } from "./index"

export const ImpressumTempl = ({ location, data }) => {
  const { t } = useTranslation()

  return (
    <Layout path={location.pathname}>
      <SEO
        title={data.strapi?.impressum?.meta?.title ?? "Impressum"}
        description={data.strapi?.impressum?.meta?.description}
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
      >
        <Text
          color="red"
          fontSize={["5xl", "5xl", "4xl"]}
          pb={["1vw", "4vw", "1vw"]}
        >
          {t("Impressum")}
        </Text>
        {onlyClient && (
          <Masonry
            breakpointColumns={{ 2: "(min-width: 48em)" }}
            gap={["5em", "5em", "4em"]}
            defaultColumn={1}
          >
            {data.strapi.impressum &&
              data.strapi.impressum.inhalt &&
              data.strapi.impressum.inhalt.map((item, index) => {
                const listItem = item.article ? (
                  <Article
                    key={index}
                    title={item.article.title}
                    subtitle={item.article.subtitle}
                    text={item.article.text}
                    useAlternativeFont={item.article.use_alternative_font}
                    showLightboxHint = "true"
                    image={item.article.cover && item.article.cover.imageFile}
                    imageGray={
                      item.article.cover && item.article.cover.imageFileGray
                    }
                    gallery={item.article.gallery}
                    justInformation={item.article.gallery.length === 0}
                  />
                ) : (
                  (item.title || item.text || item.image) && (
                    <Article
                      key={index}
                      title={item.title}
                      text={item.text}
                      textColor="green"
                      useAlternativeFont={item.use_alternative_font}
                      showLightboxHint = "true"
                      image={item.image && item.image.imageFile}
                      justInformation={
                        item.__typename &&
                        (item.__typename.includes("Information") ||
                          item.__typename.toLowerCase().includes("text"))
                      }
                    />
                  )
                )

                return listItem
              })}
          </Masonry>
        )}
      </Box>
    </Layout>
  )
}

export default ImpressumTempl
